

























import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { ProjectEntityModel } from '@/entity-model/project-entity';
import ProjectService from '@/service/project';
import { UserStoreModule } from '@/store/modules/user';
import CommonService from '@/service/common';

@Component
export default class ProjectSelectComponent extends BaseComponent {
    projectList: Array<ProjectEntityModel> = null;
    created() {
        ProjectService.getAllProject().then(res => {
            this.projectList = res;
        });
    }
    async selectProjectClick(project: ProjectEntityModel) {
        UserStoreModule.SET_CURRENT_PROJECT(project);
        await CommonService.getUserPermissions2(0);
        this.$router.push('/dashboard/index');
    }
}

